@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);
@import url(https://fonts.googleapis.com/css?family=Exo:400,700);
/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

.overviewCard{
    padding: 8px 20px;
    width: 100%;
    position: relative;
    border-radius: 5px;
    color: white;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.blueCard{
    background: linear-gradient(to right, #2660FC, #04B9FF);
}
.greenCard{
    background: linear-gradient(to right, #15C194, #00D97C);
}
.yellowCard{
    background: linear-gradient(to right, #FBC66D, #FCE57D);
}
.grayCard{
    background: linear-gradient(to right, #ADB9C6, #D7DCE0);
}
.selectBox{
    background-color: #e1e1e1;
    padding: 4px 20px;
    border-radius: 8px;
    color: black;
    border: none;
    font-weight: bold;
}
.selectBox:focus{
    outline: none;
}
.startDate{
    margin-right: 10px;
    background-color: #D9D9D9;
    padding: 4px 20px;
    border-radius: 8px;
    color: black;
    border: none;
    font-weight: bold;
}
.endDate{
    margin-right: 10px;
    background-color: #D9D9D9;
    padding: 4px 20px;
    border-radius: 8px;
    color: black;
    border: none;
    font-weight: bold;
}
.tableRow:hover{
    background-color: rgb(238, 238, 238);
    border-radius: 4px;
    cursor: pointer;
}
.progressBar div div{
    position: relative;
}
.progressBar div div span{
    font-size: 10px !important;
    font-weight: bold !important;
    color: white;
    position: absolute;
    left: 6%;
}
.p-inputtext{
    color: black !important;
    padding: 10px 5px;
    border-top-left-radius: 8px;
    background-color: #F2F2F2;
    font-weight: bold;
    border: none;
}
*{
    margin: 0px;
    padding: 0px;
}

body{
    font-family: 'Exo', sans-serif;
}


.context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area{
    /* background: #4e54c8;   */
    /* background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);   */
    position: fixed;
    width: 100%;
    height:100vh;
    
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
