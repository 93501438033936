.overviewCard{
    padding: 8px 20px;
    width: 100%;
    position: relative;
    border-radius: 5px;
    color: white;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.blueCard{
    background: linear-gradient(to right, #2660FC, #04B9FF);
}
.greenCard{
    background: linear-gradient(to right, #15C194, #00D97C);
}
.yellowCard{
    background: linear-gradient(to right, #FBC66D, #FCE57D);
}
.grayCard{
    background: linear-gradient(to right, #ADB9C6, #D7DCE0);
}
.selectBox{
    background-color: #e1e1e1;
    padding: 4px 20px;
    border-radius: 8px;
    color: black;
    border: none;
    font-weight: bold;
}
.selectBox:focus{
    outline: none;
}
.startDate{
    margin-right: 10px;
    background-color: #D9D9D9;
    padding: 4px 20px;
    border-radius: 8px;
    color: black;
    border: none;
    font-weight: bold;
}
.endDate{
    margin-right: 10px;
    background-color: #D9D9D9;
    padding: 4px 20px;
    border-radius: 8px;
    color: black;
    border: none;
    font-weight: bold;
}
.tableRow:hover{
    background-color: rgb(238, 238, 238);
    border-radius: 4px;
    cursor: pointer;
}
.progressBar div div{
    position: relative;
}
.progressBar div div span{
    font-size: 10px !important;
    font-weight: bold !important;
    color: white;
    position: absolute;
    left: 6%;
}
.p-inputtext{
    color: black !important;
    padding: 10px 5px;
    border-top-left-radius: 8px;
    background-color: #F2F2F2;
    font-weight: bold;
    border: none;
}